body {
  font-family: Tahoma, Helvetica, sans-serif;
  color: #1e1e1e;
  font-size: 12px;
  line-height: 15px;
  background-color: #f0ece1;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #2789ce;
  outline: none;
}

a:hover {
  color: #2789ce;
}

ul,
li {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.col-right {
  float: right;
  width: 486px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  height: 100%;
  position: relative;
}

.slip-back dt,
.slip-lay dt,
.slip-book dt,
.slip-back dt .slip-type,
.slip-lay dt .slip-type,
.slip-book dt .slip-type,
.slip-back dd,
.slip-lay dd,
.slip-book dd,
.fancy-head h4,
.sportsbook-head h4,
.bookmaker h4 {
  float: left;
}

.col-info .add-pin,
.col-info .add-pin-position,
.col-info .delete-pin,
.icon-in_play,
.icon-no_play,
.in-play.game-fancy:before,
.in-play.game-sportsbook:before,
.in-play.game-bookmaker:before,
.in-play.game-live:before,
.odd-add .up,
.odd-add .down,
.delete,
.match-odds-table .icon-predict,
.match-odds-table .backall-shape {
  background-image: url("../images/sprite1.png") !important;
}

.theme_accordian .accordion-button:after {
  background-image: url("../images/plus.png") !important;
  width: 16px;
  background-size: 14px;
  height: 16px;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/minus.png") !important;
  transform: none;
}

img {
  max-width: 100%;
}

.theme_accordian .accordion-button:focus,
button:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.slip-head,
.matched-head {
  background-color: #ced5da;
  padding: 0 10px;
  margin-bottom: 2px;
  justify-content: space-between;
}

.slip-head,
.matched-head,
.for-agent,
.head-bets-agent,
.expand>td {
  box-shadow: inset 0 2px 0 rgb(0 0 0 / 10%);
}

.game-list .game-list-col {
  margin-bottom: 2px;
}

.slip-head li,
.matched-head li {
  padding: 5px 0;
}

.col-center {
  float: left;
}

.col-center {
  height: 100%;
  padding: 0 15px;
  margin-left: 0;
  width: calc(100% - 809px);
}

.inplay_page_wrap .col-center {
  width: calc(100% - 486px);
  padding-left: 0;
}

.theme_accordian .accordion-body {
  padding: 0;
}

.game-titlesmall span {
  color: #508d0e;
  margin: 0 5px;
}

/* theme-reset-css */

.col3 .col-game,
.col3 .game-list-col dt,
.game-list-col .col3 dt,
.slip-head .col-game,
.game-list .col-matched {
  width: calc(53.4% - 42px);
}

.col-visit,
.col-home,
.col-draw,
.col-mdl,
.slip-head .col-matched {
  width: 13.3%;
  padding: 2px;
}

.game-list-col dd a {
  width: 49%;
  font-size: 11px;
  line-height: 26px;
  padding: 4px 0;
  cursor: pointer;
  display: inline-block;
  min-height: 34px;
  margin-bottom: 0;
}

.btn-back,
.btn-lay {
  color: #1e1e1e;
  font-weight: bold;
  margin-right: 1%;
  background-color: #72bbef;
  border-left-color: #fff;
  float: left;
  text-align: center;
}

.btn-lay {
  background-color: #faa9ba;
  border-left-color: #fff;
}

.suspend {
  position: absolute;
  width: 36.6%;
  height: calc(100% - 2px);
  background-color: #fff;
  border: 1px solid #d0021b;
  color: #d0021b;
  z-index: 5;
  text-align: center;
}

dd .suspend,
.event-left dd .suspend,
.event-right dd .suspend,
.col3 dd .suspend {
  width: calc(100% - 3px);
  height: 34px;
  margin-left: 0;
  line-height: 30px;
  top: 2px;
  right: 0;
  left: 0;
}

.game-list-col dd {
  position: relative;
  align-self: center;
  margin-bottom: 0;
}

.game-list-col dt .game-list-time {
  color: #777;
  max-width: 105px;
  margin: 0 6px;
  font-weight: normal;
}

.game-live {
  width: 18px;
  background-color: #1876b2;
}

.game-bookmaker:after {
  background-image: url("../images/bbbb.svg");
}

.game-fancy {
  background-color: #0a92a5;
}

.game-bookmaker {
  background-color: #226bc3;
}

/* inplay */

.icon-in_play {
  margin-right: 5px;
  background-position: -406px -2787px;
  height: 8px;
  width: 8px;
  vertical-align: top;
  margin-top: 4px;
}

.theme_accordian .accordion-button:after {
  background-image: none;
}

.theme_accordian .accordion-button {
  background-position: 100% -1670px !important;
  background-repeat: no-repeat !important;
}

.slip-head .col-matched {
  padding: 5px 6px 5px 0;
}

.col-info .add-pin,
.col-info .delete-pin {
  background-position: -398px -57px !important;
  background-repeat: no-repeat !important;
  margin: 6px 6px;
  height: 21px;
  width: 19px;
  min-height: auto;
}

.col-info .add-pin-position {
  background-position: -398px -36px !important;
  background-repeat: no-repeat !important;
  margin: 6px 6px;
  height: 21px;
  width: 19px;
  min-height: auto;
}

.accordion-body .add-pin,
.accordion-body .add-pin-position {
  margin: 8px 6px;
}

.game-list-col dt .game-low_liq {
  padding: 0 5px;
  display: inline-block;
  border-radius: 3px;
  line-height: 16px;
  font-size: 10px;
  vertical-align: text-top;
  background-color: #d0021b;
  color: white;
}

.game_table_main_wrapper {
  background-color: #fff;
}

.game-E {
  display: inline-flex;
  right: 0;
  height: 16px;
  background-color: #fff;
  line-height: 16px;
  font-size: 10px;
  color: #1f5172;
  border-radius: 4px;
  border: 1px solid #1f5172;
  margin-right: 4px;
  padding: 0 3px 0 0;
  overflow: hidden;
  vertical-align: middle;
}

.icon-no_play {
  margin-right: 5px;
  background-position: -406px -3023px;
  height: 8px;
  width: 8px;
  margin-top: 4px;
}

.match-sidebar ul li .icon-no_play {
  margin-top: -1px;
}

.game-E i {
  position: relative;
  width: 18px;
  height: 100%;
  background-color: #1f5172;
  margin-right: 2px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.game-E i:before {
  content: "";
  width: 7px;
  height: 8px;
  background-image: url("../images/eetap.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 4px;
  display: block;
  margin: 2px 4px;
}

.game-E i:after {
  position: absolute;
  right: 0;
  content: "";
  border-left: 5px solid transparent;
  border-bottom: 14px solid #fff;
  top: 0;
}

.game-live:after {
  content: "";
  clear: both;
  display: block;
  background-image: url("../images/play-icon.svg");
  background-repeat: no-repeat;
}

.game-fancy:after,
.game-sportsbook:after,
.game-bookmaker:after,
.game-live:after {
  content: "";
  background-size: cover;
  width: 12px;
  height: 12px;
  display: block;
}

.in_play {
  font-weight: bold !important;
  color: #508d0e;
  margin: 7px;
}

.full-wrap {
  position: relative;
  min-width: 1350px;
  max-width: 100%;
  height: calc(100vh - 108px);
  margin: 0 auto;
  display: inline-block;
  width: 100%;
  padding: 0 15px;
  overflow-x: hidden;
}

.theme_tabs>.nav-tabs {
  min-width: 375px;
  width: 40%;
  margin: 10px 0 10px;
  border: 1px solid #3b5160;
  border-radius: 5px;
  overflow: hidden;
}

.theme_tabs>.nav-tabs .nav-item {
  list-style: none;
  width: 33.333%;
}

.live-casino-theme-tabs>.nav-tabs .nav-item {
  width: 50% !important;
}

.game-list-col {
  position: relative;
  padding: 0 6px 0 10px;
  border-bottom: 1px solid #e0e6e6;
  display: flex;
}

.game-list-col .col-visit,
.game-list-col .col-home,
.game-list-col .col-draw {
  border-right: 1px solid #eee;
  min-height: 38px;
}

.game-list .game-list-col {
  padding-right: 32px;
}

.game-list-col .col-info {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.theme_tabs>.nav-tabs .nav-item .nav-link {
  background-color: #fff;
  border-right: 1px solid #3b5160;
  color: #3b5160;
  font-size: 13px;
  width: 100%;
  border-radius: 0;
  padding: 0px 10px;
  line-height: 30px;
  font-weight: bold;
  border-top: none;
  border-left: none;
}

.theme_tabs>.nav-tabs .nav-item:last-child .nav-link {
  border: none;
}

.theme_tabs>.nav-tabs .nav-item .nav-link.active {
  background-color: #3b5160;
  color: #fff;
}

.theme_accordian .accordion-button {
  background: #243a48;
  font-size: 12px;
  color: #fff;
  padding: 0 10px;
  line-height: 25px;
  border-radius: 0 !important;
}

.theme_accordian .accordion-item {
  margin-bottom: 15px;
  border-radius: 0;
}

.game-list-col .col-mdl {
  color: #777;
  padding: 5px 6px 5px 0;
  text-align: right;
  line-height: 24px;
  border-right: 1px solid #eee;
  margin-right: 5px;
}

.in-play.game-fancy:before,
.in-play.game-sportsbook:before,
.in-play.game-bookmaker:before,
.in-play.game-live:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-position: -395px -2758px;
  height: 16px;
  width: 21px;
  border-radius: 3px 0 0 3px;
  display: block;
  clear: both;
}

.in-play.game-fancy,
.in-play.game-sportsbook,
.in-play.game-bookmaker,
.in-play.game-live {
  position: relative;
  overflow: hidden;
  width: 35px;
  display: inline-flex;
  margin: 0 2px;
  height: 16px;
  align-items: center;
  border-radius: 3px;
}

.in-play.game-fancy:after,
.in-play.game-sportsbook:after,
.in-play.game-bookmaker:after,
.in-play.game-live:after {
  margin-left: 21px;
}

.game-fancy:after {
  background-image: url(../images/fff.svg);
}

.game-fancy,
.game-sportsbook,
.game-bookmaker,
.game-live {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  border-radius: 3px;
  width: 17px;
  height: 16px;
  padding: 0 !important;
  vertical-align: middle;
}

.game-sportsbook {
  background-color: #e4550f;
}

.game-sportsbook:after {
  background-image: url(../images/pl.svg);
}

.game-list-col dt .game-titlesmall {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 14px);
  margin-bottom: 0px;
  display: inline-block;
}

.game-list .game-list-col:last-child {
  border: none;
  margin-bottom: 0;
}

.game-list-col dd a:hover {
  color: #333;
}

.max-heightminus96 {
  max-height: calc(100% - 96px);
}

/* .max-heightminus131 {
  max-height: calc(100vh - 154px);
  overflow-y: auto;
} */

/* rightbar */

.loading-wrap {

  top: 20%;
  height: 90px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding-top: 25px;
  z-index: 99;
  position: absolute;
  left: 50%;
  width: 190px;
  margin-left: -95px;
}

.loading-wrap.multi-load {
  padding-top: 15px;
  height: 66px;
  top: 10%;
  margin-top: -10px;
}

.loading {
  padding: 5% 0;
  text-align: center;
}

.loading li {
  list-style: none;
  font-size: 11px;
  color: #243a48;
  margin-bottom: 3x;
}

.loading img {
  background-position: -42px -415px;
  height: 22px;
  width: 38px;
}

.table01,
.table-s {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 15px;
}

.table01 caption,
.table-s caption {
  background-color: #3b5160;
  border-bottom: 1px solid #7e97a7;
  color: #fff;
  line-height: 24px;
  font-weight: bold;
  padding: 0 10px;
}

.table01 th,
.table-s th {
  color: #243a48;
  background-color: #e4e4e4;
  border: 1px solid #7e97a7;
  border-width: 1px 0 1px 0;
  padding: 8px 10px;
}

.table01 td,
.table-s td {
  border-top: 1px solid #eee;
  padding: 8px 10px;
  vertical-align: middle;
}

.table01.margin-table td:first-child,
.margin-table.table-s td:first-child {
  padding: 8px;
}

.table01 a,
.table-s a {
  text-decoration: underline;
}

.message-bet h4,
.message-bet .tc-content h5,
.tc-content .message-bet h5 {
  font-weight: normal;
  line-height: 24px;
  padding: 0 5px;
}

.message-bet p {
  color: #243a48;
  font-size: 11px;
  line-height: 25px;
  padding: 0 5px;
  margin-bottom: 0;
}

.cursor-none::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../images/bg-disabled.png");
  margin: 2px;
  cursor: no-drop;
}

/**new11/10/20====**/

.team_list_wrapper h4 {
  font-size: 12px;
  padding: 5px;
  background-color: #fff;
}

.blue_bg_tuch {
  background: #beddf4;
  border-bottom: 1px solid #7dbbe9;
}

.preset_value {
  margin: 10px 0 10px 0;
}

.blue_bg_tuch .preset_value {
  border-top: 1px solid #7dbbe9;
  background: #d4e8f8;
}

.blue_bg_tuch .min-bets {
  background-color: #d4e8f8;
  border-top: 1px solid #7dbbe9;
}

.lay_bg_tuch {
  background-color: #f3dce2;
  border-bottom: 1px solid #dfa3b3;
}

.lay_bg_tuch .preset_value {
  border-top: 1px solid #dfa3b3;
  background: #faeff2;
}

.lay_bg_tuch .min-bets {
  background-color: #faeff2;
  border-top: 1px solid #dfa3b3;
}

.col-center .tab-content {
  /* max-height: calc(100vh - 160px); */
  overflow-y: auto;
}

.min-bets {
  padding: 5px 15px 20px 15px;
}

.team_list_wrapper {
  padding-bottom: 10px;
}

.match_oddds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 20px;
}

.up_down_rate input[type="text"]:focus {
  outline: none;
}

.up_down_rate input[type="text"] {
  width: 70px;
  border-radius: 4px;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #f1f1f1;
  margin-bottom: 0;
}

.up_down_rate input[type="number"]:focus {
  outline: none;
}

.up_down_rate input[type="number"] {
  width: 70px;
  border-radius: 4px;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #f1f1f1;
  margin-bottom: 0;
}

.up_down_rate ul {
  display: flex;
  margin: 0;
}

.match_oddds h5 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.match_oddds h5 small {
  display: block;
  color: #9b9b9b;
  font-weight: normal;
  margin: 0;
}

.tips-popup {
  background: #f8e71c;
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 4px;
  top: -4px;
}

.slip-list {
  position: relative;
}

.preset_value ul {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.preset_value li a {
  background: linear-gradient(360deg, #f3f3f3, #ffffff);
  border: 1px solid #bbbbbb;
  padding: 3px 10px;
  border-radius: 4px;
  display: inline-block;
  color: #000;
  min-width: 70px;
  text-align: center;
}

.preset_value ul li {
  padding: 4px;
}

.preset_value ul li:first-child {
  padding-left: 0;
}

.preset_value ul li:last-child {
  padding-right: 0;
}

/* .full_btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
} */

.liability {
  font-size: 14px;
  font-weight: bold;
  color: #7e7e7e;
  padding: 10px;
  text-align: right;
}

.liability .red {
  color: #d0021b;
  font-weight: normal;
  padding-left: 5px;
}

.btn-wrap {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: center;
  margin-bottom: 10px;
}

.btn-wrap .btn {
  background: linear-gradient(2deg, #efefef, #ffffff00);
  border: 1px solid #bbbbbb;
  padding: 2px 15px;
  font-size: 13px;
  border-radius: 4px;
  color: #333;
  min-height: 26px;
  width: calc(100% - 10px);
  margin-right: 10px;
}

.btn-wrap .btn-send {
  background: #ddd;
  border: 1px solid #bbbbbb;
  padding: 5px 15px;
  font-size: 13px;
  border-radius: 4px;
  display: inline-block;
  color: #333;
  min-height: 26px;
  width: calc(100% - 10px);
  margin-left: 10px;
}

.btn-wrap li {
  flex: 1;
  text-align: center;
}

.slip-option {
  padding: 0 15px;
}

.game_banner {
  margin-bottom: 15px;
}

.game_list_filter_shape {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #67f0c2 8%, #67f0c2 100%);
  padding: 5px 10px;
}

.game_list_filter_shape h4 {
  font-size: 14px;
  margin: 0;
}

select:focus {
  outline: none;
}

.game_filter_filter_right select {
  width: 108px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: inherit;
  appearance: none;
  padding: 5px 10px;
  margin: 0;
  z-index: 1;
  box-shadow: inset 0px 1px 0px 0px rgb(255 255 255 / 50%);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  margin-left: 10px;
}

.games_center_block {
  height: 100%;
  overflow-y: auto;
}

.game_table_main_wrapper .slip-head {
  padding-right: 34px;
}

/**end11/10/20====**/

/* sm */

.home_header {
  /* background:linear-gradient(-180deg, #000000 0%, #000000 58%); */
  padding: 10px 0;
  background: #000 url(../images/bg-top-skyEX.webp) repeat-x;
}

input,
textarea,
.form-control {
  font-family: Tahoma, Helvetica, sans-serif;
  color: #1e1e1e;
  font-size: 12px;
  border: 0px #aaa solid;
  background: #fff;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px;
  margin: 0 5px 5px 0;
  box-sizing: border-box;
}

.search-wrap::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 2px;
  display: block;
  content: "";
  width: 19px;
  height: 19px;
  background-image: url("../images/homeM/download.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}

.search-input {
  width: 280px;
  padding-left: 25px;
  margin: 0;
  height: 25px;
  box-sizing: border-box;
}

.one_click img {
  background-image: url("../images/homeM/sprite_img.png");
  background-repeat: no-repeat;
}


.home_header .login-wrap li.user {
  padding-left: 35px;
  background-repeat: no-repeat;
  background-image: url(../images/user.png);
}

.login-wrap input,
.login-wrap textarea {
  width: 130px;
  height: 25px;
  margin: 0;
}

.login-wrap .valid-code {
  position: relative;
  display: block;
}

.login-wrap .valid-code img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 55px;
  height: 15px;
}

.login-wrap .btn-login,
.login-wrap .btn-signup {
  width: 80px;
  /* height: 25px; */
  line-height: 25px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 4px;
}

.login-wrap .btn-login {
  background-color: #e83523;
  padding: 0px 14px;
  font-size: 12px;
  border-color: transparent;
  display: flex;
  align-items: center;
}

.login-wrap li {
  margin: 0 6px 2px 0;
  text-align: center;
}

.login-wrap .btn-signup {
  display: block;
  width: 76px;
  background-color: #666666;
  background-image: linear-gradient(-180deg, #666 0%, #333 100%);
}

.menu-wrap {
  background:linear-gradient(180deg, #67f0c2 8%, #67f0c2 100%);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.75);
  margin-top: -3px;
}

.menu-wrap ul {
  display: flex;
  align-items: center;
  margin: 0;
  float: right;
  padding: 0;
  color: #000;
}

.navbar {
  padding: 0;
}

.tag-live {
  position: absolute;
  top: -7px;
  right: 0px;
  min-width: 33px;
  height: 12px;
  border-radius: 3px;
  padding-right: 3px;
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  background-image: linear-gradient(180deg, #fb3434 0%, #e80505 100%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}

.menu .select,
.setting-wrap .select {
  box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
  background-image: linear-gradient(180deg, #dbbc68 0%, #ffdc7a 61%);
  background-position: inherit;
}

.menu-wrap .time_zone {
  margin: 0;
  padding: 0 12px;
}

.menu a,
.setting-wrap a {
  color: #222;
  position: relative;
}

.menu li,
.setting-wrap li {
  border-right-color: rgba(69, 32, 7, 0.2);
}

.menu li,
.setting-wrap li {
  border-right: 1px solid #383838;
}

.menu-wrap a {
  display: block;
  border-right: 1px solid rgba(69, 32, 7, 0.2);
  color: #000;
  position: relative;
  padding: 8px 10px;
  font-weight: bold;
}

.menu-wrap a.active {
  box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
    background-image: linear-gradient(180deg, #8ec5b6 0%, #8ec5b6 61%);
  background-position: inherit;
}

.setting-wrap {
  font-weight: bold;
}

/* .time_zone {
  color: #fff;
} */

.setting-wrap li {
  border-right-width: 0;
}

.time_zone span {
  color: #000;
  font-weight: normal;
}

.menu-wrap a.one_click {
  padding: 6px 12px 0 7px;
}

.one_click {
  /* border-top-color: #67f0c2 !important; */

  background-image: linear-gradient(180deg, #444 13%, #1b1b1b 81%);
}

.one_click.select a {
  color: #000 !important;
}

.one_click a {
  color: #d3e9df !important ;
}

.one_click .form-check-input {
  width: 16px;
  height: 16px;
}

.one_click img {
  background-position: -94px -8260px;
}

.one_click {
  border-top: 3px solid #d3e9df !important;
  margin-top: -3px;
  background-repeat: repeat-x;
}

.tag-live strong {
  flex: 1;
  height: 100%;
  margin-right: 3px;
  padding: 0 2px;
  border-radius: 3px 0px 0px 3px;
  background-image: linear-gradient(180deg, #fff 0%, #eee 89%);
}

.menu-wrap ul li {
  list-style: none;
  padding: 0;
}

.on_live {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  width: 120px;
  padding: 6px;
  background-image: linear-gradient(180deg,
      #000 0%,
      rgba(0, 0, 0, 0.7) 82%,
      transparent 100%);
}

.on_live dt {
  margin-bottom: 12px;
}

.on_live .live_icon {
  height: 20px;
  line-height: 20px;
  color: #fff;
  padding-right: 5px;
  font-size: 12px;
  font-weight: bold;
  background-image: linear-gradient(180deg, #fb3434 0%, #e80505 100%);
  border-radius: 3px;
  margin-bottom: 0;
  display: inline-flex;
}

.on_live .live_icon span {
  height: 100%;
  padding: 0 3px;
  margin-right: 5px;
  background-image: linear-gradient(180deg, #fff 0%, #e8e8e8 100%);
  border-radius: 3px 0 0 3px;
}

.on_live dd {
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 7px;
  vertical-align: top;
  flex-wrap: wrap;
  display: flex;
}

.on_live dd p {
  flex: 1;
  margin-bottom: 0;
  line-height: 16px;
}

.on_live dd span {
  color: #333333;
  background: #ffffff;
  text-align: center;
  border-radius: 3px;
  padding: 0 3px;
  min-width: 16px;
  height: 16px;
}

.games_main {
  height: 100%;
}

.games_main .title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000 97%);
  border-bottom: 5px solid #67f0c2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.games_main .title h2 {
  line-height: 42px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  padding: 0 10px;
  margin: 0;
}

.live_casino_game .title h2 {
  font-size: 14px !important;
}

.games_main .title span {
  background: #67f0c2;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  padding: 12px 20px;
  position: relative;
}

.games_main .title span::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: -30px;
  height: 0;
  width: 50px;
  border-bottom: 42px solid #67f0c2;
  border-left: 30px solid transparent;
  border-right: 10px solid transparent;
}

.main {
  background-color: #eeeeee;
}

.footer1 {
  margin: 20px 0 0px;
  padding: 6px 0 40px;
}

.support-wrap.extend-support {
  display: block;
  background-color: transparent;
  border-radius: 8px;
  margin: 0 auto 10px auto;
  padding: 5px 0px;
}

.footer1 .extend-btn {
  text-align: center;
  background-color: #fff;
  padding: 8px 0;
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.5);
  font-size: 13px;
  justify-content: center;
  margin-bottom: 8px;
}

.extend-btn img {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  vertical-align: middle;
}

.support-wrap.extend-support a {
  color: rgba(0, 0, 0, 0.5);
  line-height: 40px;
  text-align: center;
}

.support-wrap.extend-support a:hover {
  color: #000;
}

.extend-btn a {
  margin-right: 8px;
}

.support-wrap.extend-support a.split-line {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  padding-left: 10px;
}

.support-wrap {
  max-width: 670px;
  display: flex;
  justify-content: center;
}

.support-social {
  flex-basis: 32%;
  background-color: #fff;
  padding: 8px 0;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid rgba(151, 151, 151, 0.5);
  font-size: 13px;
  justify-content: center;
  text-align: center;
}

.support-social img {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  vertical-align: middle;
}

.power-wrap {
  max-width: 670px;
  margin: 0 auto 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
}

.browser-wrap {
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  text-align: center;
}

.browser_icon i {
  font-size: 20px;
  margin: 0 4px 4px;
  color: #8f8f8f;
}

.policy-link {
  padding-top: 8px;
  margin: 8px auto 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}

.policy-link li {
  margin-bottom: 5px;
}

.policy-link li:after,
.policy-link li:first-child:before {
  margin: 0 6px;
  content: "-";
}

.policy-link a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

.policy-link a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

.app-link {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
}

.app-link img {
  width: 155px;
  height: auto;
}

.power-wrap .border-end {
  border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.support-social,
.footer1 .extend-btn {
  margin-bottom: 10px;
}

.navbar-toggler-icon {
  background-image: url("../images/nav.png");
  opacity: 0.8;
}

/* sm */

.col-right .theme_accordian {
  height: 100%;
}

.col-right .accordion {
  height: 100%;
}

.col-right .accordion-item {
  height: 100%;
}

.col-right .accordion-collapse {
  height: calc(100% - 25px);
}

/* .col-right .bet_slip {
  max-height: 692px;
  overflow-y: auto;
} */

.col-right-inner {
  background-color: #fff;
}

/* murli-css */

.match-sidebar {
  background: #fff;
  width: 323px;
  float: left;
  height: 100%;
  overflow-y: auto;
}

.match-sidebar h6 {
  background: #222;
  color: #fff;
  padding: 8px 9px;
  font-size: 12px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.match-sidebar ul li a {
  color: #000;
}

.match-sidebar ul li.active {
  background-color: #000;

}

.match-sidebar ul li.active a {
  color: #fff;
}

.match-sidebar ul li .dropdown-toggle::after {
  float: right;
}

.match-sidebar ul li.menu-group-title {
  background-color: #dddcd6;
}

.match-sidebar ul li {
  padding: 5px 8px;
  border-bottom: 1px solid #e0e6e6;
}

.match-sidebar ul li .dropdown-menu {
  position: relative !important;
  transform: translate(0) !important;
  border: none;
  max-height: 466px;
  overflow-y: auto;
  overflow-x: hidden;
}

.match-sidebar ul li .dropdown-menu .dropdown-item {
  font-size: 12px;
  padding: 6px 10px;
}

.match-sidebar .dropdown-menu span {
  display: block;
  background: #dddcd6;
  color: #000;
  padding: 7px 10px;
  font-size: 12px;
  margin: 0px -8px 6px;
}

.match-wrapper {
  display: flex;
}

.common-wrapper {
  width: calc(100% - 333px);
  margin-left: 10px;
}

.sportsbook-head h4.in-play {
  background-image: linear-gradient(180deg, #f26d1c 15%, #d14100 100%);
  color: #fff;
  font-size: 13px;
  border-radius: 0px 14px 1px 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.info-popover {
  width: 32px;
  margin-left: 10px;
  position: relative;
  padding: 13px 9px;
  background-position: -380px -1501px;
}

.sportsbook-head h4.in-play #sportsBookRules {
  margin-left: 8px;
  color: #fff;
}

.sportsbook-head h4.in-play span {
  padding: 6px 2px 6px 11px;
}

/* .info-popover::before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 1px solid #1a121236;
  height: 100%;
  top: 0;
  transform: rotate(-14deg);
} */
#sportsBookName {
  background-image: url("../images/sprite1.png") !important;
}

.fancy-info dt {
  color: #7e97a7;
  margin-right: 6px;
}

.fancy-info dt span {
  width: 56px;
  background-color: #bed5d8;
  border-radius: 3px;
  color: #3b5160;
  font-size: 11px;
  display: block;
  padding: 3px 6px;
  text-align: center;
}

.fancy-info {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.fancy-info dd {
  margin-right: 8px;
  color: #000;
}

.premier-tab {
  background-image: linear-gradient(180deg, #f26d1c 15%, #d14100 100%);
  box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 20%);
  justify-content: center;
  align-items: center;
  padding: 5px 0px 0px;
}

.premier-tab .nav-tabs {
  margin: 0 auto;
  justify-content: center;
  width: max-content;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  border: none;
}

.premier-tab .nav-tabs .nav-item.show .nav-link,
.premier-tab .nav-tabs .nav-link.active {
  background: transparent;
  border: none;
}

.premier-tab .nav-tabs .nav-link {
  border: none;
  min-width: 70px;
  line-height: 9px;
  color: rgba(163, 64, 5, 0.85);
  font-weight: bold;
  border-radius: 4px;
  box-sizing: border-box;
}

.premier-tab .nav-tabs .nav-link.active {
  background-color: #fff;
}

.premier-tab .nav-tabs .nav-link:hover {
  border: none;
}

.premier-tab .tab-content {
  /* padding: 10px; */
  background: #fff;
}

.back-1 {
  background-color: #72e3a0;
  text-align: center;
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.premier-tab table td {
  padding: 8px 10px;
  font-weight: 700;
  border-bottom: 1px solid #7e97a7;
  height: 52px;
  line-height: 1;
}

.back-1 a {
  color: #000;
}

.premier-tab .accordion-item {
  margin-bottom: 15px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
}

.premier-tab .accordion-header a {
  color: #fff;
  padding: 0px 11px;
  margin-right: 10px;
  height: 23px;
  border-radius: 3px;
  margin-left: -9px;
  margin: 0 10px 0 -10px;
  z-index: 1;
  background-position: -388px -1035px;
}

.quick-batting-wrapper {
  background-color: #d3edd0 !important;
}

.quick-batting-wrapper .max-batting {
  width: 49%;
  text-align: right;
  justify-content: end;
}

.quick-batting-wrapper .max-batting d1 a {
  display: inline-block;
  width: 50px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  margin-right: 5px;
  border-radius: 3px;
  text-align: center;
  color: #000;
  margin-right: 10px;
}

.quick-batting-wrapper .max-batting .form-check {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.col-btn a {
  background: linear-gradient(360deg, #f3f3f3, #ffffff);
  border: 1px solid #bbbbbb;
  padding: 9px 19px;
  border-radius: 4px;
  display: inline-block;
  color: #000;
  min-width: 70px;
  text-align: center;
}

.bookmakerCancelBtn {
  background: linear-gradient(360deg, #f3f3f3, #ffffff);
  border: 1px solid #bbbbbb;
  padding: 9px 19px;
  border-radius: 4px;
  display: inline-block;
  color: #000;
  min-width: 70px;
  font-weight: 700;
  text-align: center;
}

.col-btn {
  width: 10%;
}

dd.quick-bet-confirm {
  width: 8%;
  text-align: right;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 4px 11px;
}

.quick-batting-wrapper dd {
  margin-bottom: 0px;
}

.quick-batting-wrapper .quick-input input {
  height: 38px;
  width: 100%;
}

.quick-batting-wrapper .quick-input {
  width: 9%;
}

.quick-batting-wrapper .batting-flex {
  border-bottom: 1px solid #23cd4085;
}

.quick-batting-wrapper .preset_value ul {
  justify-content: end;
}

dd.quick-bet-confirm span {
  display: block;
  font-size: 10px;
  line-height: 12px;
  color: #222;
  opacity: 0.5;
}

.quick-batting-wrapper .preset_value li a {
  padding: 6px 10px;
  color: #000;
  min-width: 95px;
  font-weight: 300;
}

dd.col-send a {
  background: linear-gradient(360deg, #120f0f, #644b4b);
  border: 1px solid #bbbbbb;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 8px 10px;
}

dd.col-send a:hover {
  color: #fff !important;
}

.bookmaker-place-bet-btn {
  background: linear-gradient(360deg, #120f0f, #644b4b);
  border: 1px solid #bbbbbb;
  color: #67f0c2   ;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  padding: 8px 10px;
}

.bookmaker-place-bet-btn:hover {
  color: #67f0c2    !important;
  font-weight: bold;
}

.col-send {
  width: 20%;
}

.to-win:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../images/arrow-icon.svg);
}

.to-win {
  color: #508d0e;
  margin: 0 5px 0 0;
  position: relative;
  display: block;
  padding: 0px !important;
}

.sportstrong {
  background-position: -399px -2825px;
  height: 15px;
  width: 15px;
  margin-left: 9px;
}

.sportstrong,
.info-popover,
.premier-tab .accordion-header a,
.add-pin,
.add-pin-position,
.odd-sec span {
  background-image: url("../images/sprite1.png") !important;
}

.pop-body li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000042;
  padding: 0px 14px;
}

.pop-body li>div.download {
  border-left: 1px solid #0000004f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 5px 14px 11px;
}

#popover-basic {
  min-width: 460px;
}

#popover-basic .popover-header {
  padding: 13px 19px;
  font-size: 17px;
  text-align: center;
}

.pop-body li>div {
  display: flex;
  align-items: center;
}

.pop-body figure.mb-0 {
  width: 23px;
}

.pop-body figcaption {
  width: calc(100% - 23px);
  font-size: 13px;
}

.pop-body figcaption h3 {
  font-size: 15px;
  padding: 0px 17px;
}

/* murli-css */

/**=========sm============**/

.col-right .theme_accordian {
  height: 100%;
}

.col-right .accordion {
  height: 100%;
}

.col-right .accordion-item {
  height: 100%;
  margin-bottom: 15px;
  position: relative;
}

.col-right .login-accordion-item {
  height: 50%;
}

.login-accordion-item .bet_slip {
  max-height: calc(318px - 25px) !important;

}

/* .col-right .accordion-collapse {
  height: calc(100% - 25px);
  overflow-y: auto;
  padding-bottom: 16px;
} */

/* .bet_slip {
  height: calc(370px - 100px);
  overflow-y: auto;
} */

.col-right .bet_slip {
  max-height: calc(750px - 25px);
  overflow-y: auto;
  margin-bottom: 0px;
}

.full_btn {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  bottom: 0;
}

.col-right-inner {
  background-color: #fff;
}

.setting .btn {
  background: none;
  border: 0;
  padding: 1px 9px;
  font-size: 12px;
  color: #000;
}

.setting .btn:hover {
  background: none;
  border: 0;
  color: #fff;
}

.setting .dropdown-menu {
  min-height: 100px;
  background-color: #e0e6e6;
  font-size: 11px;
  color: #3b5160;
  padding: 10px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
  min-width: 282px;
  margin-top: 6px;
}

.setting .dropdown-toggle::after {
  display: none;
}

.stake-set,
.odds-set {
  position: relative;
  border-bottom: 1px solid #7e97a7;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  line-height: 15px;
  padding-bottom: 2px;
  margin-bottom: 5px;
}

.stake-set .col-defult,
.odds-set .col-defult {
  width: 100%;
  margin: 0 5px 5px 0;
}

.stake-input {
  max-width: 80px;
  font-size: 11px;
  margin-left: 5px;
}

.edit_wrap {
  display: flex;
}

/* .edit_wrap>.d-flex {
  width: calc(100% - 60px);
} */

.col-edit {
  width: 60px;
  border: 1px solid #7e97a7;
  border-right: 1px solid rgba(69, 32, 7, 0.2);
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
}

/* .stake-set .d-flex>div {
  padding: 0 4px;
  width: 25%;
  margin: 0 0 3px;
} */

.stake-set .d-flex>div a:hover {
  color: #222;
  background-color: #fff;
  border: 1px solid #adaaaa;
}

.stake-set .d-flex>div a,
.btn-wrap .cancel-btn {
  color: #222;
  background-color: #fff;
  border: 1px solid #adaaaa;
  margin: 0;
}

.btn-wrap .cancel-btn {
  border-radius: 4px;
}

.stake-set .d-flex>div a.select,
.btn-send {
  color: #67f0c2    !important;
  background: #444 url(../images/btn-select-skyEX.jpg) repeat-x !important;
  border: 1px solid #222;
}

.btn-wrap .btn-send,
.btn-wrap .cancel-btn {
  display: flex !important;
  justify-content: center !important;
  padding: 4px 12px;
}

.btn-wrap .cancel-btn a {
  margin: 0;
}

.col-edit .btn-edit {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.setting .dropdown-menu .btn-wrap {
  float: none;
}

.setting .dropdown-menu .btn-wrap li {
  width: 50%;
}

.odds-set h6 {
  font-weight: 600;
  font-size: 11px;
  color: #3b5160;
}

.odds-set label {
  font-weight: 500;
}

.one_click.select {
  color: #1e1e1e !important;
  background-image: linear-gradient(180deg,
      #00c15d 0%,
      #009347 100%) !important;
  background-position: inherit;
}

.login_modal {
  background-color: #fff;
  position: relative;
  border-radius: 10px;
}



.login_close {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #e9e5e5;
  background: #000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_main {
  height: 100%;
  padding: 90px 25px 100px 0;
}

.login-main h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #000;
}

.login_verify {
  position: relative;
}

.login_verify img {
  position: absolute;
  right: 0;
  height: 15px;
  top: 50%;
  width: 80px;
  transform: translateY(-50%);
}

.login_main input,
.login_main textarea {
  width: 100%;
  height: 33px;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #aaa;
  box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.login_btn {
  background: #67f0c2     url(../images/bg-send.gif) repeat-x;
  border-color: #000;
  height: 36px;
  line-height: 1;
  text-align: center;
  margin: 15px 0 0;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  display: block;
  width: 100%;
  border-radius: 0;
  background-size: auto 100%;
  font-weight: bold;
}

.login_btn:hover {
  color: #67f0c2   ;
  border-color: #000;
  background-color: transparent;
  background-size: auto 100%;
}

.setting-wrap li .setting {
  position: relative;
  top: 0px;
}

.login_modal_wrap .modal-dialog {
  max-width: 540px;
}

.login_right,
.my_account .btn,
.reload_link {
  font-size: 12px;
  height: auto;
  line-height: initial;
  border: 0;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%);
  padding: 0;
  display: inline-block;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.my_account .btn,
.my_account .btn:first-child:hover {
  background: none;
  color: #67f0c2;
  border: 0;
}

.login_right:hover a {
  text-decoration: underline;
  text-decoration-color: #67f0c2;
}

.login_right a {
  display: flex;
}

.login_right span {
  color: #67f0c2;
  font-weight: 500;
  opacity: 0.7;
}

.login_right h5 {
  color: #67f0c2;
  font-size: 12px;
  margin: 0 5px;
}

.head_count {
  border: 1px solid #67f0c2;
  color: #67f0c2;
  height: 14px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 3px;
  padding: 0 8px;
}

.reload i {
  color: #67f0c2;
  margin: 0 0 0 8px;
}

.reload {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

/**===========RR==============**/
.theme_accordian .accordion-item:last-child {
  margin-bottom: 0;
}

.game-titlesmall span.blue_color {
  color: #2789ce;
}

.match-odds-table .icon-predict {
  background-position: -398px -2158px;
  height: 16px;
  width: 16px;
}

.match-odds-table .bets td {
  padding: 6px 10px;
  width: 8.97436%;
  border-bottom: 1px solid #7e97a7;
  border-left: 1px solid #ddd;
  font-weight: bold;
  vertical-align: top;
  text-align: center;
}
.new-bet td{
  background: #faf8d8;
}
.new-bet-book td{
  background: #faf8d8;
}

.match-odds-table .bets .back-3 {
  background-color: #d7e8f4;
  border-left-color: #fff;
}

.match-odds-table th {
  border-bottom: 1px solid #7e97a7;
}

.match-odds-table th p a {
  margin-right: 5px;
}

.match-odds-table .bets .back-2 {
  background-color: #b7d5eb;
  border-left-color: #fff;
}

.match-odds-table .bets .back-1s {
  background-color: #72bbef;
  border-left-color: #fff;
}

.match-odds-table {
  margin-bottom: 10px;
  margin-top: 15px;
}

.match-odds-table .bets .lay-1 {
  background-color: #faa9ba;
  border-left-color: #fff;
}

.match-odds-table .bets .lay-2 {
  background-color: #efd3d9;
  border-left-color: #fff;
}

.match-odds-table .bets .lay-3 {
  background-color: #f6e6ea;
  border-left-color: #fff;
}

.match-odds-table .bets .bet-all a,
.match-odds-table .bets-HS .bet-all a,
.match-odds-table .bets-GH .bet-all a {
  position: relative;
  width: 100%;
  line-height: 2;
  cursor: pointer;
}

.match-odds-table table {
  width: 100%;
}

.match-odds-table .bets td span,
.match-odds-table .bets-HS td span,
.match-odds-table .bets-GH td span {
  font-weight: normal;
  font-size: 11px;
  display: block;
}

.match-odds-table .horse-info {
  width: 292px;
  height: 31px;
}

.refer_only {
  position: absolute;
  top: -24px;
  font-size: 11px;
  color: #7e97a7;
  line-height: 23px;
  margin-left: 5px;
}

.match-odds-table .bets .refer-bet {
  font-size: 11px;
  line-height: 22px;
  font-weight: normal;
  width: 19%;
}

.match-odds-table .bets .bet-all td.refer-book {
  width: 19%;
}

.match-odds-table .back-all_td {
  background: #72bbef;
  border-radius: 10px 0 0 0;
  padding: 4px 0;
  border-left: none;
}

.match-odds-table .back-all_td a,
.match-odds-table .lay-all_td a {
  color: #333;
}

.match-odds-table .lay-all_td {
  background: #faa9ba;
  border-radius: 0 10px 0 0;
  padding: 4px 0;
  border-right: none;
}

.match-odds-table .bet-all td {
  border-left: none;
}

.match-odds-table th {
  padding-left: 10px;
  min-width: 270px;
  margin-bottom: 0;
  padding: 8px;
}

.match-odds-table th p {
  margin: 0;
}

.match-odds-table .bets .bet-all td {
  padding: 0px 5px;
}

.back-all,
.lay-all_td {
  padding: 0;
}

.lose,
.to-lose {
  color: #d0021b;
  margin: 0 5px 0 0;
}

/* 12-sm */
.account-wrap {
  width: 220px;
  margin: 0;
}

.account-wrap li {
  line-height: 25px;
  float: none;
  text-indent: 0;
  margin: 0;
}

.account-wrap h4,
.account-wrap h5,
.account-wrap h5 {
  line-height: 27px;
  color: #3b5160;
  border-bottom: 1px solid #7e97a7;
  padding: 0 0 0 10px;
  font-size: 11px;
  font-weight: 600;
}

.gmt {
  font-size: 11px;
  border-left: 1px solid #c5d0d7;
  padding: 0 5px;
  font-weight: 600;
}

.account-wrap a {
  color: #1e1e1e;
  padding: 0 10px;
  border-bottom: 1px solid #e0e6e6;
  display: block;
  align-items: center;
  font-size: 12px;
}

.account-wrap .logout {
  padding: 7px 10px 5px;
  cursor: pointer;
}

.account-wrap .logout a {
  background-color: #7e97a7;
  border-bottom-width: 0;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
}

.login_right .dropdown-toggle {
  border: 0;
  font-size: 12px;
}

.login_right .btn-primary,
.login_right .btn-primary:hover {
  padding: 0;
  color: transparent;
  background: none;
}

.my_account .dropdown-toggle::after {
  appearance: none;
}

.my_account .dropdown-menu {
  width: 220px;
  box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
  padding: 0px;
}

.wallet-detail-group {
  margin: 0;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.wallet-detail-group .wallet-detail-content {
  padding: 10px 7px 5px;
  border-bottom: 1px solid #ccc;
}

.login_right .dropdown-menu {
  width: 100%;
  font-size: 12px;
  color: #3b5160;
  background-color: #e0e6e6;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  z-index: 99;
  padding: 10px;
}

.login_right .dropdown-menu:hover a {
  text-decoration: none !important;
}

.main_balance h5 {
  color: #3b5160;
}

.main_balance span {
  font-size: 17px;
  line-height: 1.2;
  font-weight: bold;
  opacity: 1;
  color: #3b5160;
}

.wallet-exposure span {
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.main_balance .badge-currency {
  margin-right: 3px;
  padding: 0 5px;
  line-height: 16px;
  font-size: 10px;
  font-weight: bold !important;
  color: #fff;
  background-color: #5f849d;
  border-radius: 3px;
}

.wallet-detail-group .wallet-exposure {
  display: flex;
  justify-content: space-between;
  font-weight: normal !important;
  padding: 7px 0;
  border-top: 1px solid #e0e6e6;
}

.recall {
  color: #3b5160;
  font-size: 15px;
  font-weight: bold;
  line-height: 2.2;
  padding: 0 8px;
  background-color: rgba(94, 190, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #7e97a7;
  cursor: pointer;
}

.close_btn {
  width: 100%;
  font-size: 13px;
  font-weight: 900;
  line-height: 31px;
  margin-bottom: 0;
  background: #eee url(../images/bg-btn.gif) repeat-x;
  border: 1px solid #bbb;
  border-radius: 4px;
  color: #000;
  margin: 0;
  display: flex;
  justify-content: center;
}

.close_btn:hover {
  background: #eee url(../images/bg-btn_hover.gif) repeat-x;
}

.today_tab {
  position: relative;
  padding: 10px 10px 0;
  background-color: #e0e6e6;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 10px;
}

.sports_filters {
  display: flex;
  align-items: center;
}

.sports_filters h5 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

.sports_filters ul li {
  list-style-type: disc;
  padding: 0 20px 0 0;
  color: #7e97a7;
  white-space: nowrap;
}

.sports_filters ul li:first-child {
  list-style-type: none;
  margin-left: 10px;
}

.today_tab .close_btn {
  padding: 5px 30px;
  line-height: 20px;
  border: 1px solid #bbb;
  font-weight: 500;
}

.today_tab .close_btn:hover {
  color: #000;
  border: 1px solid transparent;
}

.today_tab .btn:first-child:hover {
  background-color: #fff;
  border-color: #bbb;
}

.today_tab .popover {
  position: absolute;
  inset: 0px auto auto 0px;
}

.today_tab .popover-arrow {
  display: none;
}

.filter_menu .btn {
  border: 0;
}

.filter_menu .btn-wrap {
  border-top: 1px solid #ccc;
  padding-top: 8px;
  padding: 5px;
}

.bs-popover-end>.popover-arrow {
  display: none;
}

.table_data h5 {
  font-size: 12px;
  margin: 0;
}

.angle_unicode {
  color: #c5d0d7;
  font-size: 20px;
}

/* table{
  border-bottom: 1px solid #7e97a7;
} */
.today_table td {
  vertical-align: middle;
}

.today_table tr td:first-child {
  font-weight: 600;
  width: 80px;
}

.today_table tr td a {
  font-weight: 600;
}

.today_table tr td a:hover {
  text-decoration: underline;
}

/* 12-sm */

.filter_popover input {
  box-shadow: none;
}

.filter_popover {
  padding: 5px 0 0;
  width: 100%;
  box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
  max-width: 500px;
}

.filter_popover .popover-body {
  padding: 0px;
}

/* murli-new */
.special_bet {
  background: #243a48;
  display: flex;
  justify-content: space-between;
}

.special_bet h3 {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 7px;
}

.special_bet p {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  margin: 0 0 0 7px;
  opacity: 0.7;
}

.add-pin,
.add-pin-position {
  color: #fff;
  padding: 3px 11px;
  margin-right: 10px;
  height: 23px;
  border-radius: 3px;
  margin: 0 10px 0 -7px;
  z-index: 1;
  background-position: -388px -1035px;
}


.bet-all:not(.match-odds-table .bet-all) {
  background-color: #faf8d8;
}

.back-gradient,
.lay-gradient {
  height: 46px;
  background-image: linear-gradient(90deg,
      rgba(130, 183, 221, 0.15) 0%,
      rgba(130, 183, 221, 0.8) 65%);
  display: flex;
  margin-bottom: 0px;
}

.back-gradient dd a,
.lay-gradient dd a {
  justify-content: center;
  align-items: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  padding: 0;
  margin: 0;
  background-color: #72bbef;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  color: #000;
  font-weight: bold;
}

.lay-gradient dd a {
  background-color: #faa9ba;
}

.back-gradient dd,
.lay-gradient dd {
  flex: 1;
  padding: 2px;
  color: #59708d;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.back-gradient dd p,
.lay-gradient dd p {
  margin-bottom: 0px;
}

.lay-gradient dd {
  color: #a9616d;
}

.lay-gradient {
  background-image: linear-gradient(270deg,
      rgba(231, 170, 184, 0.15) 5%,
      rgba(231, 170, 184, 0.8) 60%);
}

.bet-all.new-bet td {
  padding: 0px 0px 0px 11px;
}

.hide-data-table {
  position: absolute !important;
  width: 59%;
  border: none;
  margin-left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000004a !important;
  color: #fff;
}

.bet-all.new-bet {
  position: relative;
}

.info-popover::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 5px;
  background: radial-gradient(#f08022, #f0802278);
  left: 0;
  bottom: -2px;
}

.premier-tab .theme_accordian .accordion-button {
  font-weight: bold;
}

.pop-body li>div.download a {
  max-width: 19px;
}

#popover-basic .popover-body {
  padding: 0px;
}

.odd-sec strong {
  height: 30px;
  line-height: 30px;
  color: #243a48;
  font-weight: bold;
  padding-left: 10px;
  background-color: #c5d0d7;
  padding: 8px 26px 8px 13px;
  border-radius: 0px 25px 0px 0px;
}

.odd-sec span {
  padding: 0px 9px 0px 20px;
  display: inline-block;
  margin-left: 10px;
  color: #508d0e;
  background-position: -399px -2825px;
}

.match-top-wrapper .max h6 {
  background: #bed5d8;
  display: block;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 8px;
  border-radius: 3px;
}

.match-top-wrapper {
  justify-content: space-between;
}

.live-match {
  display: flex;
  align-items: center;
}

.live-match strong {
  padding: 0px 12px;
}

.live-match h5 {
  margin: 0;
  height: 23px;
  line-height: 23px;
  background-image: linear-gradient(180deg, #1e5f89 0%, #194f72 100%);
  border-radius: 3px;
  color: #fff;
  padding: 0 7px;
  margin: 3px 7px 4px 5px;
  font-size: 12px;
  position: relative;
  padding: 0px 12px 0px 31px;
}

.live-match h5::before {
  content: "";
  background-position: -391px -2873px;
  /* background-image: url(/static/media/sprite1.b901d2f1.png) !important; */
  position: absolute;
  left: 0;
  width: 25px;
  height: 21px;
}

.match-btn li {
  position: relative;
}

.match-btn .btn-pin {
  background-image: url(../images/right-back.svg);
  display: flex;
}

.match-btn li:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 25px;
  background: rgba(255, 255, 255, 0.5);
}

.match-btn .btn-refresh {
  background-image: url(../images/left-back.svg);
  display: flex;
}

.match-btn a {
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

.match-btn a img {
  width: 10px;
}

/* murli-new */
.sidenav .match-sidebar {
  transform: translate(0);
}

/**==================my-account================**/

.sidebar .sidebar-main ul li a {
  text-decoration: none;
  color: var(--secondary);
  font-size: 12px;
  display: block;
  padding: 6px 11px;
  border-bottom: 1px solid #0000002b;
}

.sidebar .sidebar-main ul li a:hover {
  color: var(--primary);
}

.sidebar-main {
  background: #fff;
}

.sidebar .sidebar-main ul li a.active {
  background: var(--secondary);
  color: #fff;
}

.account-table .table th {
  color: #243a48;
  background-color: #e4e4e4;
  border: 1px solid #7e97a7;
  border-width: 1px 0 1px 0;
  padding: 8px 10px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
}

.account-table td {
  font-size: 13px;
  background: #fff;
  color: #000;
  border-bottom-color: #5642424f;
  text-align: center;
  min-height: 16px;
  padding: 8px 10px;
  border-bottom: 1px solid #e0e6e6;
}

.account-table td:first-child {
  text-align: left;
}


.my_account_table_wrap tr:last-child td {
  border-bottom: 1px solid #7e97a7;
}

.my_account_table_wrap table {
  background: #fff;
  min-height: 61px;
}

.common-heading {
  font-size: 13px;
  font-weight: bold;
}

.my_account_table_wrap h5 {
  background-color: #7e97a7;
  color: #fff;
  line-height: 24px;
  padding: 0 10px;
  font-size: 13px;
  margin-bottom: 0;
}

/* murli-bet-history */

.common-tab .nav-tabs .nav-item .nav-link {
  padding: 5px 15px 6px;
  background-color: #ffffff;
  border: 1px solid #3b5160;
  border-radius: 3px 3px 0 0;
  color: #3b5160;
  font-size: 15px;
  font-weight: bold;
}

.common-tab .nav-tabs .nav-item {
  margin-right: 3px;
}

.common-tab .nav-tabs .nav-item .nav-link.active {
  background-color: #3b5160;
  color: #fff;
  border-color: 3b5160;
}

.common-tab .nav-tabs {
  border-bottom: 3px solid #3b5160;
}

.bet_status {
  padding: 10px 10px 10px;
  background-color: #e0e6e6;
  border-bottom: 1px solid #7e97a7;
}

.form-select {
  border-radius: 0px;
  font-size: 13px;
  padding: 3px 9px;
  border: 1px solid #0000008f;
}

.bet_status .bet-sec label.form-label {
  flex: 0 0 36%;
  max-width: 36%;
  font-size: 13px;
  margin: 0;
}

.my-bet-sec .my_account_table_wrap h5 {
  background: #3b5160;
}

.total-balance-summary dt {
  font-size: 18px;
  font-weight: bold;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 14px;
}

.total-balance-summary strong {
  color: #2789ce;
  font-size: 30px;
}

.total-balance-summary strong sub {
  font-size: 12px;
  color: #7e97a7;
  font-weight: normal;
}

.bet-history-menu li {
  display: inline-block;
  border: 1px solid #3b5160;
  border-width: 1px 0;
  width: 33.33%;
}

.bet-history-menu li a {
  background-color: #fff;
  border-right: 1px solid #3b5160;
  color: #3b5160;
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  padding: 2px 23px;
  width: 100%;
  text-align: center;
}

.bet-history-menu li:last-child a {
  border-radius: 0 3px 3px 0;
}

.bet-history-menu li a.active {
  background-color: #3b5160;
  color: #fff;
}

.bet-history-menu ul {
  border-left: 1px solid #3b5160;
  border-radius: 5px;
  overflow: hidden;
  width: 427px;
}

.bet-history-menu li a.active {
  background-color: #3b5160;
  color: #fff;
}

.bet-history-menu li:first-child a {
  border-radius: 3px 0 0 3px;
}

.betting-time-zone input[type="text"] {
  width: 46px;
}

.betting-time-zone input[type="date"] {
  width: calc(100% - 46px);
}

.betting-time-zone .d-flex {
  width: 100%;
}

.input-list li a {
  background: #eee url(../images/bg-btn.gif) repeat-x;
  border: 1px solid #bbb;
  border-radius: 4px;
  color: #1e1e1e;
  /* font-weight: bold; */
  line-height: 13px;
  font-size: 12px;
  padding: 6px 10px;
  display: block;
}

.input-list li {
  display: inline-block;
  margin-right: 10px;
}

ul.input-list {
  margin-top: 10px;
  margin-bottom: 2px;
}

.input-list li a.btn-send {
  font-weight: 700;
}

.betting-data p {
  margin-bottom: 9px;
}

.betting-data {
  max-width: 691px;
}

.profile-wallet-heading h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 7px;
  color: #3b5160;
}

.profile-wallet-heading .acc-info li {
  display: inline-block;
  padding: 0px 18px;
  background-image: url("../images/all-icons.png");
  background-repeat: no-repeat;
}

.acc-info .user {
  background-position: -12px -205px;
}

.acc-info .time {
  background-position: -12px -190px;
}

.total-balance-summary {
  display: flex;
}

.total-balance-summary .total-left-balance {
  flex: 0 0 32%;
  max-width: 32%;
  border-right: 1px solid #d8d8d8;
}

.total-balance-summary .wlcome-sec {
  padding-left: 14px;
}

.total-balance-summary .wlcome-sec p {
  margin: 0;
  line-height: 20px;
}

.total-balance-summary .wlcome-sec h6 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #3b5160;
}

/* murli-bet-history */

/***/

.my_account_sidebar {
  max-width: 234px;
  flex: 0 0 234px;
}



.main_content_row {
  display: flex;
}

.my_account_main {
  max-width: calc(100% - 234px);
  flex: 1;
  padding-left: 15px;
}

.sidebar .sidebar-main ul li a:hover {
  background-color: #f2dca7;
}

.sidebar .sidebar-main ul li a.active {
  background-color: #f2dca7;
}

.path {
  background: #222;
  position: relative;
  height: auto;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  background-repeat: repeat-x;
  background-position: 0 -8124px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.path-back {
  transform: rotateZ(90deg);
  display: inline-block;
  font-size: 18px;
  color: #ffd562;
  letter-spacing: 1px;
  position: relative;
}

.path-back:hover {
  color: #ffd562;
}

.path ul {
  margin: 0;
}

.path ul .path-last a {
  padding: 2px 0px !important;
}

.path ul .path-last a:hover {
  background-color: transparent !important;
}

.path ul {
  margin: 0;
}

.path-back:after {
  content: "";
  background-image: url("../images/arrow.png");
  width: 13px;
  height: 28px;
  position: absolute;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
  top: -20px;
  left: 3px;
}

/* smriti */

.casino_modal .casino_main h6 {
  font-size: 14px;
  color: #243a48;
}

.casino_modal .casino_main h5 {
  font-size: 20px;
  font-weight: 600;
  color: #243a48;
  margin: 0;
}

.casino_modal .modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #243a48;
}

.casino_border {
  border-left: 1px solid #bbb;
}

/* 14-sm */
.casino_modal .price_range {
  background-color: #cde3f0;
  padding: 20px;
}

.casino_modal .price_range .casino_range {
  border: 1px solid #bbb;
  background-color: #fff;
  border-radius: 5px;
}

.casino_modal .price_range input {
  border: 0px #cde3f0 solid;
  background: #fff;
  box-shadow: none;
}

.casino_modal .price_range input .form-range {
  height: auto;
}

.casino_range .col-md-8 {
  border: 1px solid #bbb;
  border-top: 0;
  border-bottom: 0;
  padding: 20px;
}

.range_input input {
  margin: 0;
  width: 240px;
  padding: 5px;
  color: #2963a7;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 3px;
  text-align: center;
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 27%) !important;
}

.range_input {
  position: absolute;
  z-index: 1;
  top: -85px;
  left: 50%;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #da8700;
  border-radius: 4px;
  color: #5e7ea3;
  font-size: 20px;
  text-indent: 0;
  transform: translateX(-50%);
}

.range_input:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -12px;
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-bottom: 1px solid #da8700;
  border-right: 1px solid #da8700;
  transform: translate(-50%, -50%) rotate(45deg);
}

.casino_modal .btn-wrap .btn-send {
  width: 100%;
  min-height: 38px;
  align-items: center;
}

.inputcanvas {
  position: absolute;
  top: 1px;
  right: 0;
  width: 44px;
  color: #000;
  background: #fff;
  height: 23px;
}

.valid-code .is-invalid {
  background-image: none;
}

.match-sidebar h6 span {
  display: block;
  background: #fff;
  padding: 1px 5px;
  color: #000;
  font-size: 11px;
  cursor: pointer;
}

.change-password-sec .form-label {
  font-size: 13px;
  min-width: 156px;
  margin: 0;
  text-align: right;
  padding-right: 17px;
}

.change-password-sec .form-control {
  font-size: 12px;
  border-radius: 0px;
  border: 1px solid #00000038;
  box-shadow: none;
}

.test-status {
  background-color: #efefef;
}

.yellow-btn {
  background: #67f0c2   ;
  border-color: #67f0c2   ;
  padding: 2px 13px;
  font-size: 13px;
  color: #000;
  border-radius: 4px;
}

.modal-title-status {
  font-size: 17px;
  color: #3b5160;
  font-weight: 500;
}

.price-button-pay-slip {
  background: linear-gradient(360deg, #f3f3f3, #ffffff);
  border: 1px solid #bbbbbb;
  padding: 3px 10px;
  border-radius: 4px;
  display: inline-block;
  color: #000;
  min-width: 66px;
  text-align: center;
}

.price-button-pay-slip:focus {
  background: linear-gradient(360deg, #f3f3f3, #ffffff);
  border: 1px solid #bbbbbb;
  padding: 3px 9px;
  border-radius: 4px;
  display: inline-block;
  color: #000;
  min-width: 66px;
  text-align: center;
}

.bet-slip-first-input {
  cursor: not-allowed;
}

.bet-cursor {
  cursor: pointer;
}

.wrapper-p>div {
  width: calc(25% - 4px);
  margin: 3px 2px;
}

.wrapper-p>div a {
  font-size: 8px !important;
  padding: 0px 5px !important;
  display: block !important;
  padding: 4px 4px !important;
}

.menu-wrap a.btn-edit {
  padding: 8px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#stakeSet .wrapper-p {
  margin-right: 7px;
  width: calc(100% - 67px);
}

.btn-edit.ok-edit {
  background-image: linear-gradient(180deg, #444 13%, #1b1b1b 81%);
  color: #67f0c2    !important;
  border-radius: 9px;
  font-weight: 300;
  width: 45px;

}

.wrapper-p>div input {
  width: 100%;
  margin: 0px;
  padding: 3px 5px;
}

.bottom-pagination ul li {
  display: inline-block;

}

.bottom-pagination {
  text-align: center;
}

.bottom-pagination ul li a {
  background: #000;
  padding: 10px 14px;
  color: #FFF !important;
  display: block;
}
.fas, .fa-solid {
  font-weight: 900;
  color: #67f0c2;
}
.bottom-pagination ul li a.pagintion-li {
  background-color: #67f0c2   ;
  color: #000 !important;
}

.login_verify .form-control.is-invalid {
  background-image: none;
}

.login_verify .inputcanvas {
  top: 5px;
}


.bet-slip-cross-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: #900113;
  font-size: 12px;
}

.back-first {
  position: absolute;
  top: 0;
  left: 12px;
  width: 596px;
  right: 0;
  background: #0000005e;
  bottom: 0;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.up_down_rate input {
  height: 35px !important;
}

.btn-back-new {
  width: 49%;
  font-size: 11px;
  line-height: 26px;
  padding: 4px 0;
  cursor: pointer;
  display: inline-block;
  min-height: 34px;
  margin-bottom: 0;
  border: none;
}

/* FOR HIDE NUMBER INPUT ARROW UP AND DOWN  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.app-loader-dev {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* 8/11/22 */

.winner-super-over span {
  font-size: 13px;
  font-weight: 600;
}

.winner-super-over h4 {
  font-size: 21px;
  margin: 0;
}

.winner-super-over .text-center {
  padding: 9px 13px;
  background: #a1d2f4;
  border-radius: 0px 0px 14px 13px;
  margin: 2px 0px;
  line-height: 25px;
}

.winner-super-over .fancy-lay {
  padding: 9px 13px;
  background: #FBB7C6;
  border-radius: 0px 0px 14px 13px;
  margin: 2px 0px;
  line-height: 25px;
}

.winner-super-accordian .accordion-button {
  background: #eef6fb !important;
  color: #000 !important;
  padding: 10px;
}

.winner-super-accordian .accordion-header a {
  background-image: url(../images/star.png) !important;
  background-repeat: no-repeat;
  background-position: 6px 3px;
  background-size: 16px;
}

/* .winner-super-accordian {

} */
.fancy-premier-tab {
  background-image: linear-gradient(180deg, #0a92a5 15%, #076875 100%);
}

.fancy-premier-tab .nav-tabs .nav-link {
  color: #076875;
}

.winner-super-accordian .nav-tabs .nav-link {
  /* color: #076875; */
  color: #000;
}

.winner-sportsbook h4.in-play,
.winner-sportsbook .info-popover::before {
  background-image: linear-gradient(180deg, #0a92a5 15%, #076875 100%);
}

.winner-sportsbook .info-popover {
  background-position: -380px -1477px;
}

.winner-super-accordian .nav-tabs .nav-link.active {
  background: #fff;

}

.winner-super-accordian .theme_accordian .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}

.winner-super-accordian .theme_accordian .accordion-button:after {
  background-image: url(../images/down-arrow.png) !important;
}

.winner-super-over {
  position: relative;
}

/* .offcanvas,
.offcanvas-backdrop {
  position: absolute;
} */
.match-odd-iframe {
  position: relative;
  width: 480px;
  background-color: #000;
  margin: 0 auto;
}

.fancy-w-tab .back-gradient,
.fancy-w-tab .lay-gradient {
  background-image: none;
}

.fancy-w-tab .back-gradient dd,
.fancy-w-tab .lay-gradient dd {
  padding: 0px;
}

.fancy-w-tab .back-gradient dd a,
.fancy-w-tab .lay-gradient dd a {
  height: 100%;
  width: 100%;
}

.fancy-w-tab .back-gradient,
.fancy-w-tab .lay-gradient {
  height: 100%;
}

.fancy-w-tab .bet-thead td {
  height: 38px !important;
}

.fancy-w-tab .bet-all:not(.match-odds-table .bet-all) {
  background-color: #fff;
}

.fancy-w-tab .min-max-rank span {
  font-size: 11px;
  color: #7e97a7;
}

.fancy-w-tab .min-max-rank span,
.fancy-w-tab .min-max-rank small {
  font-weight: 300;
}

.fancy-w-tab .hide-data-table {
  width: 167px;
  right: 246px;
}

.fancy-w-tab .bookmaker-place-bet-btn.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancy-premier-tab .preset_value ul li button {
  padding: 4px 12px;
  min-width: 114px;
  font-weight: 300;
}

/* 8/11/22 */
/* 8/11/22 */


/* 11/11/12 */

.one_click-setting.white-halo {
  background-color: #008841;
  display: flex;
  justify-content: space-between;
}

.oneclickbet-footer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: -182px;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: 0;
  max-width: 1016px;
  margin: 0 auto;
}

.oneclickbet-footer .edit_wrap {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 21px;
  background-color: #008841;
}

.oneclickbet-footer .edit_wrap .wrapper-p1 {
  width: 100%;
  display: flex;
}

/* .oneclickbet-footer .input-wrapper-footer {
  width: 48% !important;
} */
.oneclickbet-footer #stakeSet {
  flex: 0 0 50%;
  max-width: 50%;
}

.input-wrapper-footer input {
  background: #00000029;
  border: 1px solid #00000080;
  color: #fff;
  text-align: center;
  font-size: 11px;
  margin: 0;
  padding: 2px 10px;
}

.input-wrapper-footer {
  width: 71% !important;
}

.oneclickbet-footer .wrapper-p1>div {
  margin: 0px 3px;
}

.oneclickbet-footer .wrapper-p1 a {
  padding: 1px 11px;
  font-size: 11px !important;
}

#stakeSet .wrapper-p1 a {
  width: 65px;
  font-size: 12px !important;
  padding: -1px 10px !important;
}

.oneclickbet-footer .col-edit a {
  color: #000;
  border-radius: 4px;
  /* color: #fff; */
  margin-top: 5px;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%);
  border: 1px solid #000;
  padding: 2px 10px;
}

.input-wrapper-footer>div {
  margin: 0px 3px;
}



.onclick-bet-modal .modal-content {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  color: #fff;
  /* text-align: center; */
}

.onclick-bet-modal .modal-content .modal-header {
  justify-content: center;
  text-align: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 10px;
}

.onclick-bet-modal .modal-content .modal-header .modal-title {
  font-size: 15px;
  display: block;
  text-align: center;
  font-weight: 600;
  width: 100%;
}

.onclick-bet-modal.modal.show {
  position: absolute;
  top: 330px;
  left: 50%;
  transform: translateX(-53%);
}

.onclick-bet-modal .modal-content .modal-body .stake-set {
  line-height: 23px;
  border: none;
  box-shadow: none;
}

.onclick-bet-modal .modal-content .modal-body {
  border: none;
}

.onclick-bet-modal .modal-footer {
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.onclick-bet-modal .modal-footer button {
  background: #121212;
  padding: 3px 61px;
  font-size: 13px;
  color: #f8e71c;
  border-color: #ffffff3d;
}

.onclick-bet-modal .modal-content .modal-body {
  border: none;
  padding: 7px 16px;
}

.onclick-bet-modal .modal-content .modal-body span {
  color: #f8e71c;
}

.onclick-bet-modal .modal-dialog {
  max-width: 320px;
}

.game-wrapper-block .add-pin,
.add-pin-position {
  margin: 0 8px 0 -4px;
  background-position: -389px -1426px;
  border-radius: 0px;
  border: none;
}

.game-wrapper-block .special_bet h3 {
  padding: 2px 7px;
  font-weight: normal;
}

.game-wrapper-block .special_bet h3 a {
  color: #fff;
}

.game-wrapper-block .special_bet p {
  background: #fff;
  color: #000;
  opacity: 1;
  padding: 2px 7px;
  border-radius: 4px;
}

.multi_refresh {
  order: 99;
  background-position: -398px -57px !important;
  height: 25px;
  width: 29px;
  background-image: url("../images/sprite1.png") !important;
  background-position: -387px -3043px !important;
  border: none;
  background: no-repeat;
}

.multi-mark-h {
  font-size: 13px;
  line-height: 20px;
  padding-top: 6px;
  margin-bottom: 6px;
}

/* 11/11/12 */
/* 11/11/12 */


.unmatch-match-sec {
  background: #efefef;
  padding: 7px 10px;
}

.unmatch-match-sec span {
  font-weight: 700;
}

.matched-unmatched .slip-head {
  padding: 9px 11px;
}

.matched-unmatched .slip-head li {
  padding: 0px;
  width: 26.66%;
  text-align: right;
}

.matched-unmatched p {
  margin: 0;
}

.matched-unmatched .match_oddds {
  padding: 7px 10px 7px 13px;
}

.matched-unmatched .table>:not(caption)>*>* {
  border-bottom-width: 0;
}

.matches_select select {
  padding: 8px;
}

.matches_select {
  padding: 10px;
}

.matched-unmatched {
  overflow-y: auto;
  max-height: 374px;
  padding-bottom: 40px;
}

.breadcum-sec .bredcum-sec-main {
  background: #000;
  color: #fff;
  padding: 8px 11px;
  border-radius: 4px;
}

.bredcum-sec-main h5 {
  font-size: 15px;
  font-weight: 400;
}

.breadcum-sec .bredcum-sec-main {
  background: #000;
  color: #fff;
  padding: 8px 11px;
  border-radius: 4px;
}

.breadcum-sec marquee {
  width: 88%;
  margin-left: 19px;
}

.breadcum-sec .bredcum-sec-main {
  background: #000;
  color: #fff;
  padding: 8px 11px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.breadcum-sec marquee a {
  text-decoration: none;
  color: #fff;
}

/* signup page rinku css */
.get_query-wrapper {
  margin: 0px auto;
  height: 100%;
  width: 100%;
  max-width: 780px;
  padding: 26px 15px;
}

.get_query-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-icon {
  line-height: 1.4;
  border-radius: 62px;
  font-weight: 600;
  font-size: 14px;
  font-family: inter;
  display: flex;
  padding: 10px;
  height: 40px;
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(240, 240, 240);
  color: rgb(0, 0, 0);
  width: 40px;
  align-items: center;
  justify-content: center;
}

.subscribe {
  font-family: Tahoma, Helvetica, sans-serif !important;
  line-height: 1.5;
  border-radius: 62px;
  font-weight: 600;
  font-size: 15px;
  font-family: inter;
  display: flex;
  padding: 10px 16px;
  height: 40px;
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(240, 240, 240);
  color: rgb(0, 0, 0);
  width: 127px;
  justify-content: center;
  align-items: center;
}

.subscribe svg {
  flex: 1;
  max-width: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.page-middle-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.page-middle-header h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

.page-middle-header strong {
  font-size: 15px;
}

.page-middle-header span img {
  max-width: 96px;
  margin: 0 0 10px 0;
}

.query_link_wrapper li a {
  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  color: inherit;
  background: #fff;
  width: 100%;
  display: inline-flex;
  padding: 12px 15px 10px;
  margin-bottom: 20px;
  border-radius: 100px;
  justify-content: space-between;
  text-decoration: none;
  border: 2px solid rgb(252, 196, 28);
  font-size: 15px;
  transition: all .3s;
  -webkit-transition: all .3s;
  position: relative;
  left: -4px;
  top: -4px;
  box-shadow: 5px 8px 0px rgb(252, 196, 28);
}

.query_link_wrapper li {
  list-style: none;
}

.query_link_wrapper {
  padding: 32px 0 20px 0;
  max-width: calc(100% - 100px);
  margin: auto;
}


.query_link_wrapper li a:hover {
  top: 0;
}



.subscribe_modal .modal-dialog {
  max-width: 520px;
  border-radius: 24px;
  overflow: hidden;
}

.subscribe_modal_content {
  background-color: rgb(255, 255, 255);
  padding: 56px 24px 24px;
  position: relative;
  border-radius: 16px 16px 0px 0px;
  height: auto;
  max-width: none;
  overflow: hidden;
}

.subscribe_modal_content .page-middle-header {
  color: #000;
  margin-bottom: 32px;
}

.subscribe_modal_content .page-middle-header strong {
  padding: 0px;
  margin: 0px;
  color: rgb(103, 107, 95);
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 1.57;
}

.subscribe_modal_content .page-middle-header span {
  width: 72px;
  height: 72px;
  border-radius: 100px;
  overflow: hidden;
  margin-bottom: 15px;
}

.subscribe_modal_content .page-middle-header span img {
  max-width: 100%;
}

.subscribe_modal_content .nav-tabs {
  justify-content: center;
}

.subscribe_modal_content .nav-tabs .nav-link.active,
.subscribe_modal_content .nav-tabs .nav-link.active:hover,
.subscribe_modal_content .nav-tabs .nav-link.active:focus {
  border-color: transparent;
  border-bottom: 3px solid rgb(0, 0, 0);
}

.subscribe_modal_content .nav-tabs .nav-link {
  border-bottom: 3px solid transparent;
  padding: 15px 20px;
  color: rgb(103, 107, 95);
  font-size: 13px;
  width: 100%;
  position: relative;
  top: 1px;
}

.subscribe_modal_content .nav-tabs .nav-link:hover {
  border-color: transparent;
  background: #f3f3f1;
}

.subscribe_modal_content .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.subscribe_modal_content .nav-tabs li {
  flex: 1;
}

.message_wrapper {
  margin: 24px 0 0 0;
  background: rgb(245, 246, 248);
  padding: 0;
  border-radius: 5px;
}

.message_wrapper input[type="text"] {
  font-size: 14px;
  padding: 12px 10px;
  width: 100%;
  border: none;
  background: transparent;
  box-shadow: none;
}

.condition {
  padding: 20px 0;
}

.condition li {
  list-style: none;
  padding: 10px 0;
  font-size: 13px;
  padding-left: 32px;
  position: relative;
  margin-bottom: 10px;
}

.condition ul {
  padding: 0;
}

.condition li input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 0;
}

.condition li span {
  font-size: 12px;
  color: #676B5F;
}

.condition li a {
  color: rgb(10, 11, 13);
  text-decoration: none;
}

.modal_subscribe_btn {
  padding: 10px;
  background: #000;
  width: 100%;
  color: rgb(255, 255, 255);
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 600;
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 64px;
  font-size: 16px;
  transition: all 75ms ease-out 0s;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: 48px;
}

.select_phone_number_adone {
  display: flex;
}

.select_phone_number_adone select {
  border: transparent;
  background: transparent;
  min-width: 63px;
  border-right: 2px solid #fff;
  margin-right: 5px;
}

.upload-right {
  opacity: 0;
  transition: all .3s;
  -webkit-transition: all .3s;
}


.query_link_wrapper ul {
  padding: 0;
}

.signup-black {
  background-color: #000;
  height: 100vh;
}






.auto_search_form {
  position: relative !important;
  width: 280px;
  z-index: 999;
}

.auto_search_form .search-clear {
  position: absolute;
  top: 1px;
  right: 0;
  border: transparent;
  background: #ddd;
  padding: 4px 7px;
}

.auto_search_form .search-input {
  width: 100%;
}

.auto_search_form .suggestion-wrap {
  background: #fff;
  max-height: 170px;
  overflow: auto;
  border-top: 1px solid #ddd;
  border-radius: 0;
  position: absolute;
  top: 23px;
  width: 280px;
}

.auto_search_form .suggestion-wrap li a {
  color: #333;
  padding: 8px 10px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  transition: all .3s;
  transition-webkit: all .3s;
}

.suggestion-wrap ul {
  margin-bottom: 0;
}

.suggestion-wrap .no-matching {
  padding: 10px;
  margin: 0;
}

.auto_search_form .suggestion-wrap li a:hover {
  text-decoration: underline;
  background-color: #E2E8ED;
}

.auto_search_form .search-wrap::before {
  top: 12px;
}

.message-bet h4 {
  font-weight: normal;
  line-height: 24px;
  padding: 0 5px;
  background-color: #d0021b;
  color: #fff;
  font-size: 12px;
}

.match-odds-table th p span {
  display: block;
}

/**=============new==========**/
.mobile_large_space {
  width: 40%;
}

.login_right {
  width: 300px;
  position: relative;
  padding: 6px 10px;
}

.login_right .head_count {
  position: absolute;
  top: 0;
  right: 0;
}

.login_right .dropdown-toggle .price_label {
  display: flex;
  align-items: center;
}


@keyframes loadBar {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
    transform: scale(1.4);
  }

  100% {
    opacity: 0.1;
  }
}

.loading-bar {
  width: auto;
  height: 23px;
  align-items: center;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0 10px 0 15px;
  border-radius: 4px 0 0 4px;
}

.loading-bar span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #6c6c6c;
  margin-right: 6px;
  opacity: 0;
  animation: loadBar 0.8s ease infinite;
  display: inline-block;
}

.match-btn .select {
  background-image: url(../images/green-background-pin.svg);
}

.bet-loader {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}























.expand table {
  width: 85%;
  border-left: 1px solid #7e97a7;
  border-bottom-width: 0;
  margin-left: 15%;
}

.profit_loss_table .table01.table-pnl {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-bottom: 1px solid #7e97a7;
  margin-bottom: 15px;
}

.profit_loss_table>td {
  position: relative;
  padding: 0;
  border-top-color: #7e97a7;
  border-bottom: 1px solid #7e97a7;
}

.profit_loss_table .table-commission {
  width: 75%;
  margin-left: 23%;
  border-right: 1px solid #7e97a7;
  background-color: #e2e8ed;
  text-align: right;
}

.profit_loss_table .sum-pl {
  background-color: #d9e4ec;
  border-top: 1px solid #7e97a7;
}

.sum-pl dt {
  width: 82%;
  padding: 0 10px 5px;
  color: #243a48;
  float: left;
  text-align: right;
  font-weight: normal;
}

.sum-pl dd {
  margin: 0;
  padding: 0 10px 5px;
  text-align: right;
}

.profit_loss_table table td {
  border-width: 0;
  padding: 8px 10px;
  text-align: right;
}

.profit_loss_table .table01.table-pnl th:not(.expand_wrap th) {
  text-align: right;
}

.profit_loss_table .table01.table-pnl th:first-child:not(.expand_wrap th) {
  text-align: left;
}

.profit_loss_table .expand_wrap {
  background-color: #e2e8ed;
  padding: 0;
}

.profit_loss_table .expand table .even td {
  background-color: #f2f4f7;
}

.profit_loss_table .expand .sum-pl td {
  background-color: #d9e4ec;
  border-top: 1px solid #7e97a7;
}



.profit_loss_table>td {
  position: relative;
  padding: 0;
  border-top-color: #7e97a7;
  border-bottom: 1px solid #7e97a7;
}

.red {
  color: #d0021b !important;
}

.expand>td {
  position: relative;
  padding: 0;
  border-top-color: #7e97a7;
  border-bottom: 1px solid #7e97a7;
}


.total-show {
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
}

.total-show li {
  float: left;
}

.total-show li.sports-switch {
  float: right;
}

.total-show select {
  width: 140px;
  margin-bottom: 0;
  font-weight: bold;
  padding: 3px 10px;
  margin-right: 10px;
}

.expand-close,
.expand-open {
  text-decoration: none;
  color: #000;
  padding-right: 20px;
  background-image: url('https://statics3.skyexchange.com/images/report/expand-close.png');
  background-repeat: no-repeat;
  background-position: right;
}

.lay {
  color: #e33a5e !important;
}

.back {
  color: #1f72ac !important;
}

.sum-pl .net_total {
  padding-top: 5px;
  border-top: 1px dotted #7e97a7;
}

.balance-percent {
  position: absolute;
  top: 21px;
  display: flex;
  justify-content: space-between;
}

.balance-percent div:first-child,
.balance-percent div:last-child {
  background-color: rgba(199, 212, 227, 0);
}

.balance-percent div {
  display: block;
  width: 3px;
  height: 6px;
  background-color: rgba(199, 212, 227, 0.8);
}

.yellow_btn {
  background: #ffc725;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}


.inner_tabs .nav-link,
.inner_tabs .nav-link:hover {
  background-color: #fff;
  border: 1px solid #3b5160;
  color: #3b5160;
  font-size: 13px;
  width: 100%;
  border-radius: 0;
  padding: 0px 10px;
  line-height: 30px;
  font-weight: bold;
}

.inner_tabs .nav-item {
  padding: 2px;
}

.inner_tabs .nav-link.active,
.inner_tabs .nav-link:hover {
  background: #3b5160;
  color: #fff;
}

.tv-control:first-of-type {
  right: 0;
  top: 0;
}

.tv-control {
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid hsla(0, 0%, 100%, .3);
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  position: absolute;
  width: auto;
  z-index: 11;
}

.tv-control a {
  color: #c8c7c7;
  height: 50px;
  width: 50px;
}

.tv-control .close:before {
  background-image: url('../images/crose.svg');
  height: 10px;
  width: 10px;
  background-size: 10px;
  margin: 11px;
}

.tv-control a:before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}

.tv-control:nth-of-type(2) {
  bottom: 0;
  left: 0;
}

.btn-volume_off:before {
  background-image: url('../images/volume.svg');
}

.btn-volume_on:before {
  background-image: url('../images/volume.svg');
}

.btn-volume_on:before {
  width: 5px;
  height: 5px;
  background-image: url('data:image/svg+xml,<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 0a8.89 8.89 0 013.699 1.75 9.204 9.204 0 012.56 3.157A9.199 9.199 0 0118 9a9.199 9.199 0 01-.94 4.093 9.204 9.204 0 01-2.561 3.156A8.89 8.89 0 0110.8 18v-2.12a6.805 6.805 0 002.647-1.43 7.164 7.164 0 001.819-2.43A7.11 7.11 0 0015.922 9a7.11 7.11 0 00-.656-3.02 7.164 7.164 0 00-1.819-2.43A6.805 6.805 0 0010.8 2.12V0zm2.7 9c0 .93-.242 1.772-.727 2.525a4.804 4.804 0 01-1.973 1.738V4.737c.83.406 1.488.985 1.973 1.738.485.753.727 1.595.727 2.525zM0 5.986h3.984L9 .947v16.106l-5.016-5.04H0V5.987z" fill="rgb(200,199,199)" fill-rule="evenodd"/></svg>')
}

.tv-control .close {
  text-indent: -99px;
  width: 40px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.tv-control a.btn-volume_off:before,
.tv-control a.btn-volume_on:before {
  background-size: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  margin: 7px 12px;
}

.tv-control a.btn-volume_off,
.tv-control a.btn-volume_on {
  width: 40px;
  height: 30px;
  display: block;
}


.hide-data-table-new {
  width: 74% !important;
}

.hide-data-table-new table td {
  border: none !important;
}


.relative_row {
  position: relative;
}

.match-odds-table .bets .bets_Suspended {
  width: 73.5%;
  left: 0;
}

.match-odds-table .bets .bets_Suspended td {
  border: none;
}






.match-odds-table .bets .relative_for td {
  width: 90px;
}


.relative_for {
  position: relative;
}

.relative_for .suspend {
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative_for .suspend span {
  font-size: 14px !important;
}

.for_saspend table {
  width: 100%;
  border: none !important;
}

.for_saspend td,
.for_saspend {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.for_saspend td dl {
  height: 40px !important;
}

.activelay {
  background-color: #f4496d !important;
  color: #fff !important;
  box-shadow: inset 0 1px 3px #00000080 !important;
}
.activeback {
  background-color: #1a8ee1 !important;
  color: #fff!important;
  box-shadow: inset 0 1px 3px #00000080!important;
}
.for_saspend {
  border-bottom: 1px solid #7e97a7 !important;
  position: relative;
}

.for_saspend td {
  padding-right: 0 !important;
}


.suspend-fancy {
  position: absolute;
  top: 0;
  background: #3c3c3c94;
  bottom: 0;
  height: 50px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: not-allowed;
}

.suspend-fancy p {
  margin: 0;
  color: #fff;
}


.for_saspend .back-gradient dd,
.for_saspend .lay-gradient dd {
  width: 50px;
}

.game-list-time {
  color: #777;
}

/* .match-odds-table .bets .lay-2,
.match-odds-table .bets .back-3 {
  height: 54px;
} */

.table-fancy .col-lay {
  background-color: #fae5eb;
  border-color: #fff
}

.table-fancy .col-back {
  background-color: #cde8fd;
  border-color: #fff
}

.home_logo img {
  max-width: 116px;
}
.green-btn {
  background: #67f0c2   !important;
  border: 1px solid #cb8009!important;
  border-radius: 5px;
  box-shadow: none;
  color: #333!important;
  font-size: 12px;
  font-weight: 700;
}

/* home page css */
.gamehall-wrap-simple {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 14px;
}
.gamehall-wrap-simple a {
  position: relative;
  grid-column: span 2;
}
.gamehall-wrap-simple a:before {
  content: '';
  padding-top: 38.35616%;
  float: left;
}
.entrance-title {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000000 97%);
  border-bottom: 5px solid #67f0c2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
.entrance-title dt {
  flex: 1;
  font-size: 15px;
  line-height: 24px;
  text-indent: 10px;
  color: #fff;
  font-weight: bold;
}
.entrance-title dd {
  width: 80px;
  line-height: 30px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  text-indent: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
.entrance-title dd:after {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 83px;
  height: 100%;
  background-color: #67f0c2;
  content: "";
  clip-path: polygon(27% 0, 100% 0%, 100% 100%, 0 100%);
}
.gamehall-wrap-simple a img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 233px;
}
.gamehall-wrap-simple a:after {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.gamehall-wrap-simple .entrance-half {
  grid-column: span 1;
}
.gamehall-wrap-simple a {
  position: relative;
  /* grid-column: span 2; */
}


.resul-filter{
  background: #E0E6E6;
  padding: 5px;
  border-bottom: 1px solid #7e97a7;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.result-time{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.result-time input{
      width: 150px;
      cursor: pointer;
      margin-top: 5px !important;
      background-position: 100% -23px;
      height: 25px;
      border-radius: 3px;
      border: #ffdead;
      border-top: 1px solid;
      padding: 2px;
      font-size: 10px;
 
}
.btn-result {
  border: 1px solid #bbb;
  border-radius: 4px;
  background: #ffc421 !important;
  color: #000 !important;
  border-color: #ffc421;
  font-weight: 700;
  line-height: 20px;
  font-size: 12px;
  margin: 0 5px 0 0;
  padding: 0 13px;
  text-decoration: none;
}



.result-table thead tr th{
  background: black;
  color: white;
  padding: 5px 10px;

}
.result-table tbody tr td{
  background: white;
  color: black;
  padding: 4px 6px;

}

.result-table tbody tr:nth-child(even) td {
background: #E5E5E5 ;
}

.load-more{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}
